import * as React from 'react';

const PinFilled = (props) => (  <svg className="icon-pin_filled" version="1.1" id="Capa_1"  
	 viewBox="0 0 512 512"  >
<g>
	<g>
		<path d="M256,120c-33.091,0-60,26.909-60,60c0,33.249,26.861,60,60,60c33.733,0,60-27.255,60-60C316,146.909,289.091,120,256,120z
			"/>
	</g>
</g>
<g>
	<g>
		<path d="M256,0C156.742,0,76,80.742,76,180c0,33.53,9.287,66.255,26.851,94.644l142.896,230.259
			c5.925,9.569,19.895,9.423,25.635-0.205L410.63,272.212C427.211,244.424,436,212.534,436,180C436,80.742,355.258,0,256,0z
			 M256,270.2c-50.345,0-90.2-40.979-90.2-90.2c0-49.629,40.571-90.2,90.2-90.2s90.2,40.571,90.2,90.2
			C346.2,228.787,306.881,270.2,256,270.2z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
)

export default PinFilled;
