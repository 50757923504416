import * as React from 'react';

const PinEmptyFill = (props) => (  <svg className="icon-pin_empty_fill" version="1.1" id="Layer_1"  
	 viewBox="0 0 512 512"  >
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{fill:#FFBD35;}
</style>
<path className="st0" d="M252.2,149.9c0,0-0.1,0.1-0.1,0.1c0.8,0.9,1.6,1.8,2.3,2.9c1.8,0.7,3.1,2.7,3.2,4.9c0.7,1.2,1.4,2.4,2.1,3.5
	c0.2-1,0.3-2,0.4-3c-0.8-1.3-1.6-2.6-2.5-3.9C255.9,152.6,254.1,151.1,252.2,149.9z"/>
<path className="st0" d="M252.1,223.6c2.2-1,4.5-1.7,6.9-2.1c-0.2-0.5-0.3-1-0.4-1.5C256.4,220.7,254.1,222.1,252.1,223.6z"/>
<path className="st0" d="M239.7,236.3c0.5,0.5,1,1.1,1.5,1.6c0.5-0.1,1-0.2,1.5-0.4c-0.8-0.8-1.6-1.6-2.2-2.5
	C240.2,235.5,240,235.9,239.7,236.3z"/>
<path className="st0" d="M279.4,225.7c0.3-0.6,0.6-1.1,1-1.6c0.1-0.2,0.3-0.5,0.4-0.7c0-0.9,0.1-1.8,0.3-2.7c-3.1,0.6-6.4,0.8-9.5,0.8
	c0.6,0.5,1.1,1.1,1.7,1.6c0.5,0.2,0.9,0.4,1.4,0.6c1.2,0.6,1.9,1.7,2.3,2.9c0.4,0.5,0.8,0.9,1.1,1.4
	C278.4,227.2,278.9,226.5,279.4,225.7z"/>
<path className="st0" d="M227.4,153.1c-0.4,0.1-0.8,0.3-1.3,0.4c-3.4,2.5-6.6,5.5-9.4,8.8c-0.5,0.6-0.9,1.1-1.4,1.7
	c0.2,0.7,0.2,1.4,0,2.2c-0.1,0.5-0.2,1-0.4,1.4c0,0.1,0,0.2,0,0.2c0.1,0.5,0.1,1.1,0.2,1.6c1.2-2,2.6-3.9,4.2-5.9
	c2.5-3,5-6.4,7.9-9.6c0.1-0.3,0.1-0.7,0.2-1C227.5,153.1,227.4,153.1,227.4,153.1z"/>
<path className="st0" d="M211.3,194.7c0.1-0.6,0.1-1.3,0.2-1.9c-1.3-2.4-2.4-5.1-3.2-7.9c-0.9,2.1-1.6,4.3-2.3,6.4
	c-0.5,3.2-1.1,6.3-1.7,9.3c0.4,0.8,0.8,1.5,1.3,2.2c2,1.8,3.8,3.9,5.5,6C210.6,204.1,210.8,199.1,211.3,194.7z"/>
<path className="st0" d="M264.8,133.3c2.4,3,4.3,6.6,6,9.7c1.6,2.9,3.2,5.8,4.7,8.6c0.2-0.1,0.3-0.2,0.5-0.3c-1.6-4.6-3.4-9-6.4-13
	c-0.9-1.2-1.1-2.4-0.8-3.5C267.5,134.3,266.1,133.8,264.8,133.3z"/>
<path className="st0" d="M229.5,136.2c-0.5,0.3-0.9,0.5-1.4,0.6c1.1,0.7,2.2,1.4,3.3,2.2c1.3-0.5,2.5-1.1,3.8-1.5
	c-1.6-0.6-3.3-1.2-4.9-1.8C230.1,135.8,229.8,136,229.5,136.2z"/>
<path className="st0" d="M221.8,144.4c-1-0.5-1.9-1-2.9-1.3c-0.5,0.8-1.1,1.7-1.6,2.6C218.8,145.2,220.3,144.8,221.8,144.4z"/>
<path className="st0" d="M205.4,147.8c-1.8,2.7-3.1,6-4.2,8.8c0.6-1.2,1.3-2.4,2.1-3.4C204,151.4,204.6,149.5,205.4,147.8z"/>
<path className="st0" d="M414,142.9c-5.4-24.6-10-45.5-26-64c-9.7-11.2-27.5-19.8-63-37c-23.6-11.5-35.7-17.2-49-20
	c-28.6-6-51.9-1.2-62,1c-6.9,1.5-39.6,9-72,36c-9.8,8.1-28.4,23.6-41,49c-21.3,42.9-11.7,86.3-3,125c2.1,9.5,11.7,50.2,39,97
	c23,39.4,31.9,37.5,67,90c32.3,48.4,34.2,64.2,49,66c11.9,1.4,23.3-7.3,83-95c49.4-72.6,61-95.3,70-123c11-33.9,12.7-58.9,13-73
	C419.5,170,415.8,151,414,142.9z M325.8,164.6c0.2,0.6,0.2,1.2,0.2,1.7c0.5,2-0.6,4.2-2.4,5.5c-0.4,1.5-0.8,2.9-1.2,4.4
	c9,19.1,0.9,43.9-9.1,63.3c-2.7,5.3-10.3,1.4-9.1-3.8c-1.8,1.9-5.1,2.6-7.1,0.5c-3.3,2-6.4,4.1-9.6,6.1c0.8,2.9-0.7,6.6-4.6,6.7
	c-2.4,0-4.8,0.2-7.3,0.5c-0.4,0.4-0.8,0.7-1.2,1.1c-2.3,1.9-5,1.4-6.8,0c-0.5,0.1-1,0.1-1.5,0.2c-2,1.1-4.1,2.1-6.2,3.1
	c-2.3,1-5.1,0.7-6.6-1.4c-5,0.5-10.1,0.5-15.2-0.3c-5.7-0.9-10.5-3-14.7-6.9c-3.5-3.3-6.2-7.5-10.9-9.4c-3.1-1.2-3.9-4.5-3-7
	c-9.2-5.6-19.8-14-21.5-25c-0.4-2.5,0.6-5.6,3.5-6.2c0.2,0,0.5-0.1,0.7-0.1c-1.2-4.3-1.6-8.9-0.8-13.3c0.9-5.3,3.6-9.7,6.9-13.8
	c0-0.1,0-0.3,0-0.4c0.1-3.2,0.4-6.6,1.3-9.7c-2.3,5.9-11.9,3.3-9.6-2.7c3.8-9.8,8.5-22.5,20.1-25.1c1.5-0.3,3-0.5,4.4-0.4
	c0.6-0.6,1.1-1.2,1.7-1.8c-0.8-2.5,0.1-5.4,3.3-6.1c11.8-2.7,24-4.4,36.2-4.4c10.4,0,22.4,0.5,31.3,6.4c1.4,0.9,2.6,1.9,3.8,2.9
	c-1.3-2.9-2.7-5.8-4.3-8.5c-3.3-5.7,4.9-10.1,8.6-5c2.2,2.9,4.4,5.8,6.6,8.6c8.3,0.9,16.1,4.1,20.5,11.6c4.8,8.1,4.7,18.1,3,27.8
	C325.6,163.8,325.7,164.2,325.8,164.6z"/>
<path className="st0" d="M292.1,170.1c0.5-1.5,0.9-3,1.1-4.5c0.2-1.1,0.7-1.9,1.3-2.5c-2.5-6-5.5-12.2-9.5-17c2,5.2,3.6,10.6,5.3,15.6
	C291.2,164.4,291.8,167.2,292.1,170.1z"/>
<path className="st0" d="M236.9,165.8c0.1-1.4,0.3-2.8,0.5-4.3c-0.6,0.2-1.1,0.5-1.6,0.8c-0.3,1.5-0.6,3-0.9,4.5
	C235.6,166.5,236.2,166.1,236.9,165.8z"/>
<path className="st0" d="M238,209.9c-0.3,1.9-0.7,4.5-0.5,6.8c1.6-0.9,3.1-2.1,4.4-3.4C240.6,212.3,239.3,211.2,238,209.9z"/>
<path className="st0" d="M227.3,215.3c-1,0.8-2.5,1.1-3.8,0.9c1.1,1.3,2.5,2,4,2.4C227.3,217.5,227.3,216.4,227.3,215.3z"/>
<path className="st0" d="M280,209.8c-0.6-1-1.1-2.1-1.5-3.3c-0.3-0.8-0.6-1.7-0.8-2.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.8,1.2-2,1.9-3.2,2.2
	c-0.2,0.3-0.4,0.6-0.6,0.9c0.1,1.2,0,2.5-0.2,3.7c1.5-0.1,2.9-0.2,4.3-0.4c0.8-0.1,1.7-0.3,2.5-0.5C280.1,210.1,280,209.9,280,209.8
	z"/>
<path className="st0" d="M261.4,185.6c-0.7-0.8-1.5-1.5-2.2-2.2c-1.4-1.4-2.9-2.7-4.4-4c-0.7,0-1.5,0.1-2.2,0.2
	c-0.1,0.9-0.3,1.8-0.5,2.6c0.8,1,1.5,2.2,2.1,3.4c1.8,0.4,3.7,1.3,5.4,2.3C260,187,260.6,186.2,261.4,185.6z"/>
<g>
	<g>
		<path className="st1" d="M256,0C153.8,0,70.6,83.2,70.6,185.4c0,126.9,165.9,313.2,173,321c6.6,7.4,18.2,7.4,24.8,0
			c7.1-7.9,173-194.1,173-321C441.4,83.2,358.2,0,256,0z M256,469.7c-55.8-66.3-152-197.2-152-284.3c0-83.8,68.2-152,152-152
			s152,68.2,152,152C408,272.5,311.9,403.4,256,469.7z"/>
	</g>
</g>
<g>
	<g>
		<path className="st1" d="M256,92.1c-51.4,0-93.3,41.9-93.3,93.3s41.9,93.3,93.3,93.3s93.3-41.9,93.3-93.3S307.4,92.1,256,92.1z
			 M256,245.3c-33,0-59.9-26.9-59.9-59.9s26.9-59.9,59.9-59.9s59.9,26.9,59.9,59.9S289,245.3,256,245.3z"/>
	</g>
</g>
</svg>
)

export default PinEmptyFill;
